export default [
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Date created',
    },
    cell: {
      type: 'slot',
      name: 'createdAt',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Pair',
    },
    cell: {
      type: 'slot',
      name: 'pair',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Author',
    },
    cell: {
      type: 'slot',
      name: 'author',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Profit',
    },
    cell: {
      type: 'slot',
      name: 'profit',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Limits',
    },
    cell: {
      type: 'slot',
      name: 'limits',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Hold',
    },
    cell: {
      type: 'slot',
      name: 'hold',
    },
  },
];
