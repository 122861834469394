//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import User from '~/components/User.vue';
import constant from '~/const';
import chatImage from './chatImage.vue';

export default {
  components: { User, chatImage },

  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      chat: {
        modal: false,
        data: [],
      },
      feedback: {
        modal: false,
      },
      showImageError: false,
      editingFeedback: {
        modal: false,
        loader: false,
        data: {
          comment: '',
          influence: '',
          feedbackId: 0,
        },
      },
      constant,
    };
  },

  computed: {
    ...mapGetters('currencies', ['toCurrencyFormat']),
    ...mapGetters('p2pDeals', ['sortedByDateChatMessages']),
    ...mapState('p2pDeals', {
      chatMessages: 'chatMessages',
      feedbackMessages: 'feedback',
    }),

    isArbitratorRequired(status) {
      return status === 'ArbitratorRequired';
    },

    isCrossTrade() {
      return Boolean(this.data.serviceTrade);
    },

    serviceTradeBuyerAmount() {
      return `${this.data?.serviceTrade?.cryptoBuyerAmount} ${this.data?.serviceAdvertisement?.quoteCurrency?.title}`;
    },

    serviceTradeSellerAmount() {
      return `${this.data?.serviceTrade?.cryptoSellerAmount} ${this.data?.serviceAdvertisement?.baseCurrency?.title}`;
    },

    cryptoTitle() {
      const { title } = this.data.cryptoSellerCurrency;
      return title;
    },

    fiatTitle() {
      const { title } = this.data.cryptoBuyerCurrency;
      return title;
    },

    exchangeRate() {
      const { exchangeRate } = this.data;
      const { fiatTitle } = this;
      return this.toCurrencyFormat(exchangeRate, fiatTitle);
    },

    serviceFee() {
      const { serviceFee } = this.data;
      const { cryptoTitle } = this;
      return this.toCurrencyFormat(serviceFee, cryptoTitle);
    },

    authorUsername() {
      const { advertisement } = this.data;
      return advertisement.author.username || '';
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setErrorNotification',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pDeals', [
      'getChatMessages',
      'getFeedback',
      'deleteFeedback',
      'editFeedback',
    ]),

    setImageError() {
      this.showImageError = true;
    },

    async showChat() {
      const { id } = this.data;

      this.setGeneralProgress(true);
      try {
        await this.getChatMessages({
          id,
          Limit: 1000,
        });
        this.chat.modal = true;
      } catch (error) {
        this.setErrorNotification('Trade chat does not exist');
      }
      this.setGeneralProgress(false);
    },

    async showFeedback() {
      const { id } = this.data;

      this.setGeneralProgress(true);
      await this.getFeedback({
        tradeId: id,
        limit: 50,
      });
      this.setGeneralProgress(false);
      this.feedback.modal = true;
    },

    prepareText(text) {
      if (!text) return '';

      const preparedText = text
        .replaceAll('<b>', '')
        .replaceAll('</b>', '')
        .replaceAll('<br>', '');
      return preparedText;
    },

    deleteFeedbackMessage(message) {
      const { id } = message;
      this.confirmAction({
        text: 'Are you sure you want to delete feedback?',
        callback: async () => {
          this.setGeneralProgress(true);
          await this.deleteFeedback(id);
          await this.getFeedback({
            tradeId: this.data.id,
            limit: 50,
          });
          this.setGeneralProgress(false);
          this.setSuccessNotification('Feedback deleted');
        },
      });
    },

    editFeedbackMessage(message) {
      const { comment, influence, id } = message;
      this.editingFeedback.data.comment = comment;
      this.editingFeedback.data.influence = influence;
      this.editingFeedback.data.feedbackId = id;
      this.editingFeedback.modal = true;
    },

    async applyEditFeedback() {
      const { comment, influence, feedbackId } = this.editingFeedback.data;
      this.setGeneralProgress(true);
      this.editingFeedback.loader = true;
      await this.editFeedback({
        comment,
        influence,
        feedbackId,
      });
      await this.getFeedback({
        tradeId: this.data.id,
        limit: 50,
      });
      this.editingFeedback.modal = false;
      this.setGeneralProgress(false);
      this.editingFeedback.loader = false;
      this.setSuccessNotification('Feedback edited');
    },

    prepareTextArray(text) {
      if (!text) return [];
      const preparedText = text.replaceAll('<br>', '');
      const arr = preparedText.split(/<b>|<\/b>/);
      if (preparedText.indexOf('<b>') === 0) arr.unshift('');
      return arr;
    },
  },
};
