//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import User from '~/components/User.vue';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import Filters from './P2POffersFilters.vue';
import Extra from './P2POffersExtra.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: {
    Filters,
    User,
    Extra,
    GetReport,
  },

  props: { authorId: { type: String, default: '' } },

  data() {
    return {
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      offerData: {},
      enableOfferState: false,
      enableOfferNotification: true,
      enableOfferComment: '',
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('p2pOffers', ['page', 'pages', 'data', 'sort']),
    ...mapGetters('p2pOffers', ['requestData']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pOffers', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      deleteOfferAction: 'deleteOffer',
      editOfferAction: 'editOffer',
      editOffersAction: 'editOffers',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData({
        ...(this.authorId && { authorId: this.authorId }),
      });
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData({
        ...(this.authorId && { authorId: this.authorId }),
      });
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData({
        extendedRequestData,
        ...(this.authorId && { authorId: this.authorId }),
      });
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getPair(row) {
      const { baseCurrency, quoteCurrency } = row;
      if (baseCurrency && quoteCurrency) {
        return `${baseCurrency.title} / ${quoteCurrency.title}`;
      }
      return '';
    },

    getFirstPaymentMethod(row) {
      return row.paymentMethods?.[0]?.name ?? '';
    },

    getPaymentMethodsOtherLength(row) {
      const length = row.paymentMethods?.length;
      return length ? length - 1 : 0;
    },

    getQuoteCurrencyTitle(row) {
      const { title } = row.quoteCurrency;
      return title;
    },

    getMinimumLimit(row) {
      const { minimumLimit } = row;
      const { title } = row.quoteCurrency;
      return this.toCurrencyFormat(minimumLimit, title);
    },

    getMaximumLimit(row) {
      const { maximumLimit } = row;
      const { title } = row.quoteCurrency;
      return this.toCurrencyFormat(maximumLimit, title);
    },

    getHold(row) {
      const { frozenAmount } = row;
      const { title } = row.baseCurrency;
      return this.toCurrencyFormat(frozenAmount, title);
    },

    deleteOffer({ id }) {
      this.confirmAction({
        title: 'Are you sure you want to delete offer?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.deleteOfferAction({
            ids: [id],
            whyDelete: prompt,
          });
          this.loader = true;
          await this.loadDataAction({});
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Offer successfully deleted');
        },
      });
    },

    disableOffer(data) {
      this.confirmAction({
        title: 'Are you sure you want to disable offer?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.editOfferAction({
            ...data,
            isEnabled: false,
            whyChange: prompt,
          });
          this.loader = true;
          await this.loadDataAction({});
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Offer successfully disabled');
        },
      });
    },

    enableOffer(data) {
      this.confirmAction({
        title: 'Are you sure you want to enable offer?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.editOfferAction({
            ...data,
            isEnabled: true,
            whyChange: prompt,
          });
          this.loader = true;
          await this.loadDataAction({});
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Offer successfully enabled');
        },
      });
    },

    toggleStateEnableoffer(data) {
      this.offerData = data;
      this.enableOfferState = true;
    },

    async confirmEnableOffer() {
      try {
        this.setGeneralProgress(true);
        await this.editOfferAction({
          ...this.offerData,
          isEnabled: true,
          sendNotification: this.enableOfferNotification,
          whyChange: this.enableOfferComment,
        });
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setGeneralProgress(false);
        this.setSuccessNotification('Offer successfully enabled');
        this.enableOfferState = false;
      } catch (error) {
        this.loader = false;
        this.setGeneralProgress(false);
        this.setSuccessNotification(error);
      }
    },

    deleteOffers() {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No offers selected');
        return;
      }
      this.confirmAction({
        title: 'Are you sure you want to delete selected offers?',
        hasPrompt: true,
        hasOkButton: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          try {
            await this.deleteOfferAction({
              ids: selection,
              whyDelete: prompt,
            });
            this.loader = true;
            await this.loadDataAction({});
            this.loader = false;
            this.setSuccessNotification('Selected offers successfully deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    enableOffers() {
      this.editOffers({ isEnabled: true }, this.$t('enable'));
    },

    disableOffers() {
      this.editOffers({ isEnabled: false }, this.$t('disable'));
    },

    editOffers(data, actionTitle = 'edit') {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No offers selected');
        return;
      }
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to selected offers?', { actionTitle }),
        hasPrompt: true,
        hasOkButton: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          try {
            await this.editOffersAction({
              ids: selection,
              whyChange: prompt,
              ...data,
            });
            this.loader = true;
            await this.loadDataAction({});
            this.loader = false;
            this.setSuccessNotification('Selected offers successfully edited');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    editTerms(data) {
      this.confirmAction({
        title: 'Enter new offer terms',
        hasPrompt: true,
        promptLabel: 'Terms',
        promptValue: data.terms,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          try {
            await this.editOfferAction({
              ...data,
              comment: prompt,
            });
            this.loader = true;
            await this.loadDataAction({});
            this.loader = false;
            this.setSuccessNotification('Terms successfully edited');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
