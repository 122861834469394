//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        SearchString: undefined,
        CreatedAtLower: undefined,
        CreatedAtUpper: undefined,
        BaseCurrencyId: undefined,
        QuoteCurrencyId: undefined,
        Statuses: [],
        AmountLower: undefined,
        AmountUpper: undefined,
        PriceLower: undefined,
        PriceUpper: undefined,
        isCrossTrade: undefined,
        limitFrom: undefined,
        limitTo: undefined,
      },
      constant,
    };
  },

  computed: {
    ...mapState('p2pDeals', ['filterLists']),
    ...mapState('p2p', ['p2pCurrencies']),

    cryptoCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.CRYPTO);
    },

    fiatCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.FIAT);
    },

    fiatAndCryptoCurrencies() {
      const { fiatCurrencies, cryptoCurrencies } = this;
      return [...fiatCurrencies, ...cryptoCurrencies];
    },

    crossTradeItems() {
      return [
        {
          id: true,
          caption: 'Cross trades only',
        },
        {
          id: false,
          caption: 'Direct trades only',
        },
      ];
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const {
      DealsSearchString,
    } = this.$route.query;

    const status = this.$route.query.Statuses;

    if (status) {
      this.filters = {
        ...this.filters,
        Statuses: status,
      };
      this.$router.replace({ query: {} });
    }

    if (DealsSearchString) {
      this.filters = {
        ...this.filters,
        SearchString: DealsSearchString || '',
      };
      this.setFilters(this.filters);
      this.$emit('update');
      this.$router.replace({ query: {} });
      this.$refs.p2pDealsBlock.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('p2pDeals', ['setFilters']),
  },
};
