var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offers"},[_c('div',{staticClass:"offers__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.P2P_OFFERS,"command":_vm.requestData}})],1),_c('div',{staticClass:"offers__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-select":"","show-actions":"","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.isDeleted)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Deleted'))+" ")]):_vm._e(),(row.isHidden)?_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.$t('Hidden'))+" ")]):_vm._e(),(row.isBlocked)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Blocked'))+" ")]):_vm._e(),(row.isEnabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_vm._e()]}},{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.createdAt))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.createdAt)))])])]}},{key:"pair",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.getPair(row)))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"body-2 text--secondary text-no-wrap text-truncate"},[_vm._v(" "+_vm._s(_vm.getFirstPaymentMethod(row))+" ")]),(_vm.getPaymentMethodsOtherLength(row))?_c('div',{staticClass:"body-2 text--secondary text-no-wrap"},[_vm._v(" (+"+_vm._s(_vm.getPaymentMethodsOtherLength(row))+") ")]):_vm._e()])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.$t(row.type)))])]}},{key:"author",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.author.id,"username":row.author.username}})]}},{key:"profit",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userProfitPercent)+"% ")]}},{key:"limits",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getMinimumLimit(row))+" – "+_vm._s(_vm.getMaximumLimit(row))+" "+_vm._s(_vm.getQuoteCurrencyTitle(row))+" ")]}},{key:"hold",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getHold(row))+" ")]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row},on:{"delete":_vm.deleteOffer,"disable":_vm.disableOffer,"enable":_vm.toggleStateEnableoffer,"editTerms":_vm.editTerms}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('v-dialog',{attrs:{"max-width":"380"},model:{value:(_vm.enableOfferState),callback:function ($$v) {_vm.enableOfferState=$$v},expression:"enableOfferState"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Are you sure want to enable offer?'))+" ")]),_c('v-card-text',[_c('v-textarea',{attrs:{"value":_vm.enableOfferComment,"dense":"","label":_vm.$t('Comment'),"outlined":"","placeholder":" ","rows":3},on:{"input":function($event){_vm.enableOfferComment = $event}}}),_c('v-checkbox',{attrs:{"label":_vm.$t('Send notification')},model:{value:(_vm.enableOfferNotification),callback:function ($$v) {_vm.enableOfferNotification=$$v},expression:"enableOfferNotification"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text--secondary",attrs:{"text":""},on:{"click":function($event){_vm.enableOfferState = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{ref:"okButton",attrs:{"color":"primary","outlined":""},on:{"click":_vm.confirmEnableOffer}},[_vm._v(" "+_vm._s(_vm.$t('Ok'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }